import React, {useEffect, useState} from 'react';

const ModalConfirmation = ({isVisible, onCancel, onConfirm, content, cancelButtonText='Annuler', showTitle=true}) => {
  const [_isVisible, setVisible] = useState(false);
  const [hasConfirmed, setConfirmed] = useState(false);
  const [hasCanceled, setCanceled] = useState(false);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    setConfirmed(typeof onConfirm === 'function');
  }, [onConfirm]);

  useEffect(() => {
    setCanceled(typeof onCancel === 'function');
  }, [onCancel]);

  return (
    <div className={`pop_up ${_isVisible ?'' : 'invisible'}`}>
      <div className='pop_up_content'>
        {showTitle &&
          <h2 style={{margin:0}}>Confirmation</h2>
        }
        <p>{content}</p>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center',gap:'1em' ,width:'100%', flexWrap:'wrap'}}>
          
          {hasCanceled &&
            <button className='filtre' onClick={onCancel}>{cancelButtonText}</button>
          }
          {!hasCanceled &&
            <button className='filtre' onClick={() => setVisible(false)}>Fermer</button>
          } 
          {hasConfirmed &&
            <button className='filtre button_confirm' onClick={onConfirm}>Confirmer</button>
          }
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmation;
