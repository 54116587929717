import React, { useEffect, useState } from 'react';
import config from '../config';
import ModalConfirmation from './ModalConfirmation';

const ArticlePageNav = ({props = null, onExitEditMode = () => {console.log("Nothing in void onExitEditMode");}, isEditingArticle = false }) => {
  const [article, setArticle] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleDelete = () => {    
    fetch(config.API_URL+'/api/data/'+article.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse the response as JSON
      })
      .then(responseData => {
        console.log('Success:', responseData);
        window.location.href = '/';
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const handleExit = (e) => {
    e.preventDefault();
    window.location.href = '/';  // Rediriger vers la page d'accueil
  };

  const handleDeleteModal = () => {
    setIsVisible(true);
  }

  useEffect(() => {
    if (props) {
      setArticle(props);
    }
  }, [props]);

  return (
    <div style={{width:'100%'}}>
        <ModalConfirmation 
          isVisible={isVisible} 
          onCancel={() => setIsVisible(false)} 
          onConfirm={handleDelete} 
          content="Êtes-vous sûr de supprimer cet article ?"/>
      <nav>

        {/* Si vous n'avez pas d'article, affichez un bouton pour quitter le mode création */}
        <div>
          {(!article || !isEditingArticle) && (
            <button onClick={handleExit} className='button_icon'>
              <i className="fa-solid fa-arrow-left"></i>
            </button>
          )}
          {(article && isEditingArticle)  && (
            <button onClick={onExitEditMode} className='button_icon'>
              <i className="fa-solid fa-arrow-left"></i>
            </button>
          )}
        </div>

        <div>
          {/* Si vous avez un article, affichez un bouton pour le supprimer */}
          {(article && isEditingArticle) && (
            <button onClick={handleDeleteModal} className='button_icon'>
              <i className="fa-solid fa-trash error"></i>
            </button>
          )}
          {/* Si vous avez un article, affichez un bouton pour quitter le mode édition */}
          {(article && !isEditingArticle) && (
            <button onClick={onExitEditMode} className='button_icon'>
              <i className="fa-solid fa-pen-to-square"></i>
            </button>
          )}
        </div>
      </nav>
      
      {article && (
        <div id='article_title'>
          <h1>{article.name}</h1>
          <p className='filtre' style={{margin:'0'}}>{article.typeName}</p>
        </div>
        )}
    </div>
  );
};

export default ArticlePageNav;
