import "./styles/App.css";
import "./styles/Footer.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Head from "./components/Head";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Catalogue from "./components/Catalogue";
import ArticleForm from "./components/ArticleForm";
import Article from "./components/ArticlePage";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      <Head />
      <Header />
        <div id="content">
          <Routes>
            <Route path="/" element={<Catalogue />} />
            <Route path="/create" element={<ArticleForm />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      <Footer />
    </Router>
  );
}

export default App;