import React, { useState, useEffect } from 'react';
import config from '../config';
import ImagePreview from './ImagePreview';
import ArticlePageNav from './ArticlePageNav';
import ModalConfirmation from './ModalConfirmation';

const ArticleForm = ({props = null, onExitEditMode, isEditingArticle = false}) => {
  const [types, setTypes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hasCreated, setCreated] = useState(false);
  const [article, setArticle] = useState(null);

  useEffect(() => {
      const fetchData = async () => {
        try {    
          // Récupérez les types et attendez leur mise à jour
          const response = await fetch(config.API_URL + '/api/types');
          if (!response.ok) {
            throw new Error("Erreur réseau");
          }
          const data = await response.json();
          setTypes(data);
    
          // Une fois les types récupérés, définissez l'article
          if (props) {
            setArticle(props);
    
            // Accédez aux données après avoir vérifié leur disponibilité
            if (props.props?.type !== undefined) {
              console.log('Nom du type:', data[props.type]?.name);
            }

            document.getElementById('nameArticle').value = props.name;
            document.getElementById('recipeArticle').value = props.recipe;
          //   document.getElementById('imageArticle').files[0] = article.link;
            document.getElementById('typeArticle').value = props.type;
            
            setSelectedFile(config.API_URL+ '/images' + props.link);
          }
        } catch (error) {
          console.error("Erreur lors de la requête:", error);
        }
      };
    
      fetchData();
  }, [props]); // Ajoutez `props` en tant que dépendance
    
  const handleSubmit =  (e) => {
    e.preventDefault();
    let nameArticle = document.getElementById('nameArticle').value;
    let recipeArticle = document.getElementById('recipeArticle').value;
    let imageArticle = document.getElementById('imageArticle').files[0];
    if (imageArticle === undefined) {
      imageArticle = article.link;
    }
    let typeArticle = document.getElementById('typeArticle').value;

    let formData = new FormData();
    formData.append('name', nameArticle);
    formData.append('recipe', recipeArticle);
    formData.append('link', imageArticle);
    formData.append('type', typeArticle);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    const createArticle = async () => {
      try {
        const response = await fetch(config.API_URL + '/api/create', {
          method: 'POST',
          body: formData,
        })
        .catch(error => {
          console.error('Error:', error);
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const responseData = await response.json();
        console.log('Success:', responseData);
        setCreated(true);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    const updateArticle = async () => {
      // Vérifier si les valeurs sont nulles ou vides
      let nameValid = nameArticle !== null && nameArticle.trim() !== '';
      let recipeValid = recipeArticle !== null && recipeArticle.trim() !== '';
      let imageValid = imageArticle !== null;
      let typeValid = typeArticle !== null && typeArticle.trim() !== '';

      // Définir des valeurs par défaut si les valeurs sont nulles ou vides
      let defaultName = nameValid ? nameArticle : article.name;
      let defaultRecipe = recipeValid ? recipeArticle : article.recipe;;
      let defaultImage = imageValid ? imageArticle : null;
      let defaultType = typeValid ? typeArticle : article.type;

      let formData = new FormData();
      formData.append('id', article.id);
      formData.append('name', defaultName);
      formData.append('recipe', defaultRecipe);
      formData.append('type', defaultType);
      formData.append('link', defaultImage);

      fetch(config.API_URL+'/api/data/'+article.id, {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          console.log('update');
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); // Parse the response as JSON
        })
        .then(responseData => {
          console.log('Success:', responseData);
          window.location.reload();
        })
        .catch(error => {
          console.error('Error:', error);
        });

    }
    if (isEditingArticle) {
      updateArticle();
    }
    else{
      createArticle();
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return(
    <div style={{marginTop:'1em'}}>
      {!isEditingArticle && <ModalConfirmation 
        isVisible={hasCreated} 
        onCancel={() => window.location.href = '/'}
        content = 'Votre article a bien été créé !'
        cancelButtonText='Fermer'
        showTitle={false}  />}

      <ArticlePageNav props={article} onExitEditMode={onExitEditMode} isEditingArticle={isEditingArticle} />

      <form className={'article'} onSubmit={handleSubmit}>
        <label htmlFor='name'>
          <span>
            Nom
          </span>
          <input id='nameArticle' name='name' onChange={(e) => {if (article) setArticle({ ...article, name: e.target.value })}} />
        </label>

        <label htmlFor='type'>
          <span>
            Type
          </span>
          <select id='typeArticle' name='type' onChange={(e) => {if (article) setArticle({ ...article, type: e.target.value })}}>
            {types.map((type) => (
              <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
        </label>

        <label htmlFor='recipe'>
          <span>
            Recette
          </span>
          <textarea
            id='recipeArticle'
            name='recipe'
            className='input_text'
            onChange={(e) => {if (article) setArticle({ ...article, recipe: e.target.value })}}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Empêche le comportement par défaut de la touche "Enter" (éviter la soumission du formulaire)
                const { selectionStart, selectionEnd, value } = e.target;
                const newValue = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;
                e.target.value = newValue;
                e.target.setSelectionRange(selectionStart + 1, selectionStart + 1); // Déplace le curseur après la nouvelle ligne
              }
            }}
          />
        </label>

        <label htmlFor='image'>
          <span>
            Image
          </span>
          <ImagePreview file={selectedFile} />
          <input id='imageArticle' name='image' type='file'
            accept='image/jpg, image/jpeg, image/webp, image/png' onChange={handleFileChange}/>
        </label>

        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
          <button type="submit">
            <p style={{marginRight:'1em'}}>
              {isEditingArticle ? 'Modifier' : 'Créer'}
            </p>
            <i className="fa-solid fa-check"></i>
          </button>
        </div>      
      </form>

    </div>
  );
};

export default ArticleForm;