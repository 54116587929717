import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Head() {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Le Bar</title>
                <script src="https://kit.fontawesome.com/9716f2641e.js" crossorigin="anonymous"></script>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
            </Helmet>
        </HelmetProvider>
    );
}

export default Head;