import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
// import ImagePreview from './ImagePreview';
import ArticleForm from './ArticleForm';
import ArticlePageNav from './ArticlePageNav';
import Image from './Image';

const ArticlePage = () => {
  //#region Variables
  const {id} = useParams();

  const [isEditMode, setIsEditMode] = useState(false); 
  const [types, setTypes] = useState([]);
  const [article, setArticle] = useState({
    id: '',
    name: '',
    link: '',
    recipe: '',
    type: '',
  });
  //#endregion

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(config.API_URL+'/api/data/' + id);
        const data = await response.json();
        const typeName = types[data.type-1] != null ? types[data.type-1].name : '';
        setArticle({...data, typeName: typeName});
      } catch (error) {
        console.error('Erreur lors de la requête :', error);
      }
    };
    
    fetchData();
  }, [id, types]);

  const fetchTypes = async () => {
    try {
      const response = await fetch(config.API_URL+'/api/types');
      if (!response.ok) {
        throw new Error("Erreur réseau");
      }
      const data = await response.json();
      setTypes(data);
    } catch (error) {
      console.error("Erreur lors de la requête:", error);
    }
  };
  
  useEffect(() => {
    fetchTypes();
  }, []);
  
  
  if (!article) {
    return <div>Loading...</div>;
  }

  const { link, recipe } = article;

  const renderRecipeLines = (recipe) => {
    const lines = recipe.split('\r\n');
    return lines.map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const imageUrl = link !== null && link !== '' ? config.API_URL + '/images' + link : null;

  return(
    <div>
      <div className={`article ${isEditMode ? 'invisible':''}`}>
        <ArticlePageNav props={article} onExitEditMode={() => setIsEditMode(true)} />

        <Image file={imageUrl} className='cardTheme' />
        {recipe &&
        <div id="article_recipe" className='cardTheme'>
          <h2>La Recette pour 1 personne :</h2>
          <div>{renderRecipeLines(recipe)}</div>
        </div>
        }
      </div>

      { isEditMode && <ArticleForm props={article} onExitEditMode={() => setIsEditMode(false)} isEditingArticle={true} /> }
    </div>
  );
};

export default ArticlePage;
