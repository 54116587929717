import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import Image from './Image';

const CardArticle = (props) => {
  const { id, name, link } = props.article;
  const imageUrl = config.API_URL + '/images' + link;

  return (
    <Link to={`/article/${id}`} className="card cardTheme" key={id}>
      <div className="image-container">
        <Image file={imageUrl} />
      </div>
      <div className="card_title">
        <h1>{name}</h1>
      </div>
    </Link>
  );
};

export default CardArticle;
