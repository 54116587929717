import React, { useState, useEffect } from 'react';

const Image = ({ file, className = '' }) => {
  const [image, setImage] = useState(null);
  // Gestion de l'état pour le chargement de l'image
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true); // L'image est chargée
  };

  // Appelez handleImageChange lorsque la prop file change
  useEffect(() => {
    const handleImageChange = () => {
      if (file) {
        if (typeof file === 'string') {
          setImage(file);
          return 
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImage(null);
      }
    };

    handleImageChange();
  }, [file]);

  return (
    <div style={{width:'100%'}} className={className || ''}>
        {!isImageLoaded && (
          <div className="loader"></div>
        )}
        <img
          src={image}
          alt='Boisson'
          onLoad={handleImageLoad} // Appelé quand l'image a fini de charger
          style={{ display: isImageLoaded ? 'block' : 'none', contain:'fill',  width: '100%', height: '100%', objectFit: 'cover' }} // Cache l'image jusqu'à ce qu'elle soit chargée
        />
    </div>
  );
};

export default Image;