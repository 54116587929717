import React, { useState, useEffect } from "react";
import ArticleItem from "./ArticleItem";
import config from '../config';

const Catalogue = () => {
  const [articles, setArticles] = useState([]);
  const [articlesFilter, setArticlesFilter] = useState([]);
  const [types, setTypes] = useState([]);
  const [activeFilter, setActiveFilter] = useState(-1);

  const fetchData = async () => {
    try {
      const response = await fetch(config.API_URL+'/api/data');
      if (!response.ok) {
        throw new Error("Erreur réseau");
      }
      const data = await response.json();
      setArticles(data);
      setArticlesFilter(data);
    } catch (error) {
      console.error("Erreur lors de la requête:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchTypes = async () => {
    try {
      const response = await fetch(config.API_URL+'/api/types');
      if (!response.ok) {
        throw new Error("Erreur réseau");
      }
      const data = await response.json();
      setTypes(data);
    } catch (error) {
      console.error("Erreur lors de la requête:", error);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  const handleFilter = (typeId) => {
    if(typeId < 0){
      fetchData();
      setActiveFilter(-1);
      return;
    }
    setActiveFilter(typeId);
    setArticlesFilter(articles.filter(article => article.type === typeId));
  };

  return (
    <div>
      <nav>
        <h2>Liste des boissons</h2>
        <a href="/create" className="button_icon"><i className="fa-solid fa-plus"></i></a>
      </nav>
      <div id="filtresBar">
        <div
          className={`filtre ${activeFilter === -1 ? 'filtre_active' : ''}`}
          onClick={() => handleFilter(-1)}
        >
          <p>Tous</p>
        </div>
        {types.map((type) => (
          <div
            key={type.id}
            className={`filtre ${activeFilter === type.id ? 'filtre_active' : ''}`}
            onClick={() => handleFilter(type.id)}
          >
            <p>
              {type.name}
            </p>
          </div>
        ))}
      </div>
      <div className="listArticles">
        {articlesFilter.map((article) => (
          <ArticleItem key={article.id} article={article} data-type={article.type} />
        ))}
      </div>
    </div>
  );
};

export default Catalogue;
