
import React from 'react';
import '../styles/notFound.css';
import ArticlePageNav from './ArticlePageNav';

const NotFound = () => {
     
     return (
      <div style={{display: 'flex', flexDirection: 'column' ,justifyContent: 'space-between', alignItems: 'center', height: '40vh'}}>
        <ArticlePageNav />
        <div class="cardDiff">
          <div class="cardDiff-body">
            <h1>404</h1>
            <h2>Page Introuvable</h2>
            <p>Désolé, la page que tu cherches n'existe pas.</p>
          </div>
        </div>
      </div>
     );
};

export default NotFound;
