import React, { useState, useEffect } from 'react';

const ImagePreview = ({ file }) => {
  const [imagePreview, setImagePreview] = useState(null);

  // Appelez handleImageChange lorsque la prop file change
  useEffect(() => {
    const handleImageChange = () => {
      if (file) {
        if (typeof file === 'string') {
          setImagePreview(file);
          return 
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    };

    handleImageChange();
  }, [file]);

  return (
    <div style={{width:'100%', marginBottom:'0.5em'}}>
      {imagePreview && (
        <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
          <img src={imagePreview} className='card' alt="Preview" />
          <p>Preview de l'image</p>
        </div>
      )}
    </div>
  );
};

export default ImagePreview;